import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EsriMapComponent } from './esri-map/esri-map.component';
import { IonicModule } from '@ionic/angular';
import { LayerListComponent } from './layer-list/layer-list.component';
import { FilterLayersPipe } from '../pipes/filter-layers.pipe';



@NgModule({
  declarations: [EsriMapComponent,LayerListComponent,FilterLayersPipe],
  imports: [
    CommonModule,IonicModule
  ],
  exports: [EsriMapComponent,LayerListComponent]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
