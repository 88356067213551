import { Component, OnChanges, OnInit } from '@angular/core';
import * as moment from 'moment';
import 'moment/min/locales';
import { SwUpdate } from '@angular/service-worker';
import { UtilService } from './services/util.service';
import { ActivatedRoute } from '@angular/router';
import { GeoCoords, layerCategory } from './models/misc';
import { MenuController } from '@ionic/angular';
import layers from 'src/assets/gmap.json';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  locationData: any;
  currentPosition: GeoCoords;
  layers: any = layers;
  searchTerm = '';
  layerCategory = layerCategory;

  public appPages = [
    { title: 'Setting', url: '/home/web', icon: 'settings' },
    { title: 'Profile', url: '/home/web', icon: 'person-circle' },
    { title: 'About Coasting', url: '/home/web', icon: 'information-circle' },
  ];

  constructor(private readonly updates: SwUpdate, private utilService: UtilService,
    private activatedRoute: ActivatedRoute, private menuCtrl: MenuController) {
    this.activatedRoute.params.subscribe(async data => {
      this.locationData = this.utilService.currentLocation;
    });

  }

  ngOnInit() {
    this.getCurrentPosition();
    moment.locale('en');
    this.updates.available.subscribe(event => {
      const updateStatus = this.updates.activateUpdate();
      document.location.reload();
    });
  }

  /**
   * @description - fetches current location
   */
  async getCurrentPosition() {
    await this.utilService.getCurrentPosition();
    const response = await this.utilService.getCurrentAddress();
    if (response) {
      this.currentPosition = this.utilService.currentPosition;
      this.locationData = this.utilService.currentLocation;
    } else {
      this.locationData = null;
    }
  }


  /**
   * @description -  Add all selected layer on map
   */
  applyChanges() {
    this.utilService.applyChanges$.next(true);
    this.menuCtrl.close();
  }

  /**
   * @description - Clear all selected layer
   */
  clearChanges() {
    this.utilService.clearChanges$.next(true);
    // this.menuCtrl.close();
  }



}
