import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import esriConfig from '@arcgis/core/config';
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import WebMap from '@arcgis/core/WebMap';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import LayerList from '@arcgis/core/widgets/LayerList';
import TileLayer from '@arcgis/core/layers/TileLayer';
import Expand from '@arcgis/core/widgets/Expand';
import { excludeLayers, Places, symbolObject } from '../../models/misc';
import Basemap from '@arcgis/core/Basemap';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import PopupTemplate from '@arcgis/core/PopupTemplate';


import { environment } from 'src/environments/environment';


import { MapType } from 'src/app/models/map-type';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-esri-map',
  templateUrl: './esri-map.component.html',
  styleUrls: ['./esri-map.component.scss'],
})
export class EsriMapComponent implements OnInit, AfterViewInit {

  @ViewChild('esriMapDiv') esriMapDiv: ElementRef;
  @Input() mapViewType = MapType.DEFAULT;
  @Input() mapType = 'web';
  @Input() mapViewOptions: __esri.MapViewProperties;
  @Input() createMapOptions: __esri.WebMapProperties;

  private mapViewProperties: __esri.MapViewProperties;
  private webMap: WebMap;
  private mapView: MapView;
  private mapProperties: __esri.WebMapProperties;
  private selectedLayers: any = [];
  private selectedLayerCount: any = [];
  private attributeInfo: any = {};

  constructor(private utilService: UtilService) {
    this.utilService.loadLayer$.subscribe(data => {
      if (data && data.layer) {
        this.loadLayerOnMap(data.layer, data.index);
      }
    });

    this.utilService.applyChanges$.subscribe(data => {
      if (data) {
        this.applyChanges(true);
      }
    });

    this.utilService.clearChanges$.subscribe(data => {
      if (data) {
        this.applyChanges(false);
      }
    });
  }


  async ngOnInit() {

    esriConfig.apiKey = environment.esriApiKey;
    // esriConfig.assetsPath = './assets';
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.createWebMap(this.createMapOptions);
  }

  async ngAfterViewInit() {
    this.mapViewOptions.map = this.webMap;
    this.mapViewOptions.container = this.esriMapDiv.nativeElement;
    this.createMapView(this.mapViewOptions, this.mapType);
  }



  private async createMapView<Type>(mapViewProperties: Type, type = 'default') {
    this.mapViewProperties = mapViewProperties;
    this.mapView = new MapView(mapViewProperties);
    this.mapView.popup.autoOpenEnabled = true;
    this.mapView.popup.defaultPopupTemplateEnabled = true;

    this.mapView.when(()=>{
      const baseMapGallery = new BasemapGallery({
        view: this.mapView,
        source: [
          Basemap.fromId('arcgis-navigation'),
          Basemap.fromId('topo-vector'),
          // Basemap.fromId('hybrid'),
          Basemap.fromId('oceans'),
          Basemap.fromId('satellite'),
          // Basemap.fromId('streets'),
          // Basemap.fromId('terrain'),
        ],
      });

      const bkExpand = new Expand({
        view: this.mapView,
        content: baseMapGallery,
        expanded: false,
        id: 'maptype',
      });

      this.mapView.ui.add(bkExpand, {
        position: 'top-right'
      });
     });


  }

  private createWebMap(mapProperties: __esri.WebMapProperties) {
    this.mapProperties = mapProperties;
    this.webMap = new WebMap(this.mapProperties);
  }


  /**
   * @description - loads layers on the map
   * @param index selected layer index
   * @param layer current selected layer
   */
  private loadLayerOnMap(layer: any, index: number) {
    layer.checked = !layer.checked;

    /**
     * @description - Load layer on map
     */
    if (layer.checked) {
      // if (!excludeLayers.includes(index)) {
        const featureLayer= new FeatureLayer({
          url: `https://webgis2.hampton.gov/server/rest/services/Public/MapServer/${index}`,
          visible: false,
          minScale: 0,
          maxScale: 100,
          popupEnabled: true,
          outFields: ['*'],
        });
        // Add FeatureLayer to the map
          this.webMap.add(featureLayer);
      // }
    }

    // eslint-disable-next-line @typescript-eslint/dot-notation
    const removedLayer = this.webMap.layers['items'].find(item => item.layerId.toString() === layer.index.toString());

    if (removedLayer) {
      removedLayer.checked = layer.checked;
    }
    if (layer.checked && removedLayer) {
      this.selectedLayers.push(removedLayer);
    }


  }

  /**
   *
   * @param isApply - Flag to show or hide the layers
   * @description - Apply changes on selected layers
   */
  private async applyChanges(isApply: boolean) {
    this.selectedLayers.forEach(layer => {
      if (isApply) {
        layer.checked = layer.checked;
        layer.visible = layer.checked;
      } else {
        layer.checked = isApply;
        layer.visible = isApply;
      }

 });
    const isExist = this.selectedLayers.filter((layer, ind) => {
      if (layer.visible) {
        return layer;
      } if (!isApply) {
        this.selectedLayers.length = 0;
      }
    });
    this.utilService.layerCount = isExist.length;
  }
}
