import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterLayers'
})
export class FilterLayersPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return null;
    if (!args) return value;
    args = args.toLowerCase();
    return value.filter((data) => JSON.stringify(data.name.replace('Public -', '')).toLowerCase().includes(args));
  }


}
