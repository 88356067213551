export interface GeoCoords {
  longitude: number;
  latitude: number;
  altitude?: number;
};

/* eslint-disable @typescript-eslint/naming-convention */

export const Places = [
  'Choose a place type...',
  'Arts and Entertainment',
  'Coffee Shop',
  'Education',
  'Food',
  'Gas station',
  'Hotel',
  'Hospital',
  'Land Features',
  'Populated Place',
  'Parks and Outdoors',
  'Residence',
  'Shops and Service',
  'Travel and Transport',
  'Water Features',
];

export const symbolObject: any = {
  type: 'simple-marker',
  color: '#F42069',
  size: '18px',
  outline: {
    color: '#ffffff',
    width: '2px'
  }
};

export const excludeLayers = [
  // 0, 1, 3, 5, 7, 8, 9, 11, 16, 17, 18, 20, 22, 26, 27, 30, 38, 42, 44, 46, 55, 58
];

export const layerCategory = [{
  value: 'Search',
  selected: false,
},
{
  value: 'Utilities',
  selected: false,

},
{
  value: 'Locations',
  selected: false,

},
];
/* eslint-disable @typescript-eslint/naming-convention */
