import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { GeoCoords } from '../models/misc';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  // private locationData: GeoCoords;
  // private currentLocation: String;
  public loadLayer$ = new BehaviorSubject<any>(null);
  public layers$ = new BehaviorSubject<any>(null);
  public applyChanges$ = new BehaviorSubject<boolean>(false);
  public clearChanges$ = new BehaviorSubject<boolean>(false);
  private currentPosition$ = new BehaviorSubject<GeoCoords>(null);
  private currentLocation$ = new BehaviorSubject<any>(null);


  // private mapView$ = new BehaviorSubject<string>(null);
  // private webMap$ = new BehaviorSubject<string>(null);
  private layerCount$ = new BehaviorSubject<number>(0);
  constructor(private http: HttpClient) { }

  get currentPosition(): GeoCoords {
    return this.currentPosition$.getValue();
  }

  set currentPosition(locationData: GeoCoords) {
    this.currentPosition$.next(locationData);
  }

  get currentLocation(): any {
    return this.currentLocation$.getValue();
  }

  set currentLocation(location: any) {
    this.currentLocation$.next(location);
  }

  // get widgetLayers(): any {
  //   return this.widgetLayer$.getValue();
  // }

  // set widgetLayers(layers: any) {
  //   this.widgetLayer$.next(layers);
  // }

  // get mapView(): any {
  //   return this.mapView$.getValue();
  // }

  // set mapView(map: any) {
  //   this.mapView$.next(map);
  // }

  // get webMap(): any {
  //   return this.webMap$.getValue();
  // }


  // set webMap(map: any) {
  //   this.webMap$.next(map);
  // }


  set layerCount(count: number) {
    this.layerCount$.next(count);
  }

  get layerCount(): number {
    return this.layerCount$.getValue();
  }


  async getCurrentPosition() {
    try {
      const data = await Geolocation.getCurrentPosition();
      const locationData: GeoCoords = {
        longitude: data.coords.longitude,
        latitude: data.coords.latitude,
        altitude: data.coords.altitude,
      };
      console.log('TCL ->  ~ file: util.service.ts ~ line 41 ~ UtilService ~ getCurrentLocation ~   this.locationDat', locationData);
      this.currentPosition = locationData;
      // return this.locationData;
    } catch (e) {
      console.log('TCL ->  ~ file: util.service.ts ~ line 45 ~ UtilService ~ getCurrentLocation ~ e', e);
      this.currentPosition = null;
    }
  }

  async getCurrentAddress() {
    return new Promise((resolve) => {
      const currentPosition = this.currentPosition;
      console.log('TCL ->  ~ file: util.service.ts ~ line 52 ~ UtilService ~ getCurrent ~ locationData', currentPosition);
      this.http.get(
        `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&featureTypes=&location=
       ${currentPosition.longitude},${currentPosition.latitude}`
      ).subscribe((response: any) => {
        this.currentLocation = response.address;
        resolve(true);
        console.log('TCL ->  ~ file: util.service.ts ~ line 56 ~ UtilService ~ GeocodeServer/r', response);
      }, err => {
        this.currentLocation = null;
        resolve(false);
        console.log('TCL ->  ~ file: util.service.ts ~ line 62 ~ UtilService ~ ).subscribe ~ err', err);

      });
    });

  }
}
