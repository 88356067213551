import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { excludeLayers } from 'src/app/models/misc';
import { UtilService } from 'src/app/services/util.service';
// import layers from '../../../assets/gmap.json';

@Component({
  selector: 'app-layer-list',
  templateUrl: './layer-list.component.html',
  styleUrls: ['./layer-list.component.scss'],
})
export class LayerListComponent implements OnInit {

  @Input() layerCategory: any;
  @Input() layers: any;
  @Input() searchTerm: string;
  @Output() emitSelectedLayers = new EventEmitter();
  public excludeLayer = excludeLayers;
  public sideMenu: any = [];

  constructor(private utilService: UtilService) {
  this.utilService.clearChanges$.subscribe(data => {
     if (data) {
      this.sideMenu.length = 0;
      this.formatLayersData();
      }
    });
  }

  ngOnInit() {
    this.formatLayersData();
  }

  /**
   * @description - Format layer records
   */
  formatLayersData() {
    this.layers.forEach((layers) => {
      const categoryNames = Object.keys(layers);
      categoryNames.forEach((name) => {
        this.sideMenu.push({
          name,
          value: name,
          selected: false,
          layers: this.layers[0][name].map((layer) => {
            layer.checked = false;
            return layer;
          })
        });

      });
    });
  }

  /**
   *
   * @param category
   * @description - Toggle the categories
   */
  toggleList(category: any) {
    category.selected = !category.selected;
  }

  /**
   *
   * @param layer Select Layer
   * @param index Index of selected layer
   * @description - Load layer on the map
   */
  loadLayerOnMap(layer: any, index: number) {
    this.utilService.loadLayer$.next({ layer, index: layer.index });
  }

}
